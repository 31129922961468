<template>
  <div>
    <div class="d-flex flex-wrap align-items-start justify-content-between">
      <div class="mb-3">
        <label for="search-decision-state">
          {{ $t("components.shared.decisions_search.filter") }}
        </label>

        <div class="row no-gutters mb-2 mb-md-0">
          <div class="col-12 col-md-auto">
            <be-form-radio-group
              v-model="searchState"
              :options="stateOptions"
            />
          </div>
        </div>
      </div>

      <div class="mb-3">
        <div class="d-flex justify-content-between">
          <label for="search-date-start">
            {{ $t("models.decision.status_changed_at") }}
          </label>
        </div>

        <div class="row no-gutters mb-2 mb-md-0">
          <div class="col-12 col-md-auto mb-2 mb-lg-0">
            <be-form-datepicker
              id="search-date-start"
              v-model="searchDateStart"
              :max-date="maxDateStart"
            />

            <be-form-checkbox
              v-if="dateStartSuggestion && dateStartSuggestion.dateString"
              v-model="suggestionSelected"
              class="mt-1 ml-1"
            >
              {{ dateStartSuggestion.text }}
            </be-form-checkbox>
          </div>

          <div class="d-none d-lg-inline col-md-auto">
            <div class="input-group-prepend input-group-append">
              <span class="input-group-text">-</span>
            </div>
          </div>

          <div class="col-12 col-md-auto">
            <be-form-datepicker
              id="search-date-end"
              v-model="searchDateEnd"
              :max-date="new Date()"
              :min-date="minDateEnd"
            />
          </div>
        </div>
      </div>

      <div class="mb-3">
        <label for="decision-search">
          {{ $t("components.shared.be_table.search") }}
          <i
            v-be-tooltip="
              $t(
                'components.shared.decisions_search.search_by_description_and_user'
              )
            "
            class="fal fa-question-circle ml-1"
          />
        </label>

        <be-input-group>
          <be-input-group-prepend>
            <be-input-group-text class="bg-transparent pr-0">
              <i class="search-icon fal fa-search" />
            </be-input-group-text>
          </be-input-group-prepend>

          <be-form-input
            id="decision-search"
            v-model="searchQuery"
            type="search"
            class="border-left-0"
            :placeholder="$t('components.shared.be_table.type_to_search')"
          />
        </be-input-group>
      </div>
    </div>

    <paginated-table
      :items="decisionsWithRowClass"
      :fields="decisionFields"
      :pagination="pagination"
      :sorting="{ sortBy, sortDirection }"
      :loading="searchLoading"
      @page-changed="paginationChanged"
      @sorting-changed="sortingChanged"
    >
      <template v-if="hasSearchedForDecisions" #empty>
        <be-alert variant="info">
          {{ $t("components.shared.be_table.no_results") }}
        </be-alert>
      </template>

      <template #description="data">
        {{ data.item.description }}
      </template>

      <template #meeting="data">
        <be-link :href="url(`/meetings/${data.item.meeting_id}/minutes`)">
          {{ meetingText(data.item) }}
        </be-link>
      </template>

      <template #status="data">
        {{ $t(`models.decision.statuses.${data.item.status}`) }}
      </template>

      <template #due_at="data">
        <div v-if="data.item.due_at">
          {{ $d(new Date(data.item.due_at), "date") }}
        </div>
      </template>

      <template #created_at="data">
        {{ $d(new Date(data.item.created_at), "date") }}
      </template>

      <template #status_changed_at="data">
        {{ latestStatusChangeDate(data.item) }}
      </template>

      <template #user_id="data">
        <user-avatar v-if="data.item.user_id" :user="data.item.user_id" />
      </template>

      <template #options="data">
        <be-button
          v-if="referenceFor(data.item.id)"
          :loading="loadingIds.includes(data.item.id)"
          variant="danger"
          size="sm"
          icon="fa-times"
          inline
          @click="$emit('remove-reference', referenceFor(data.item.id))"
        />

        <be-button
          v-else
          :loading="loadingIds.includes(data.item.id)"
          size="sm"
          inline
          @click="$emit('add-reference', data.item.id)"
        >
          {{ $t("buttons.titles.add_more") }}
        </be-button>
      </template>
    </paginated-table>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

import { parsePagination } from "@/helpers/pagination";
import UserAvatar from "@/components/user/UserAvatar.vue";
import PaginatedTable from "@/components/shared/PaginatedTable.vue";

export default {
  components: {
    UserAvatar,
    PaginatedTable,
  },

  props: {
    references: {
      type: Array,
      required: true,
    },

    loadingIds: {
      type: Array,
      required: true,
    },

    excludeMeetingId: {
      type: Number,
      required: false,
      default: null,
    },

    dateStartSuggestion: {
      type: Object,
      required: false,
      default: null,
    },
  },

  emits: ["add-reference", "remove-reference"],

  data() {
    return {
      decisions: [],
      pagination: parsePagination({}),
      searchQuery: "",
      searchDateStart: null,
      searchDateEnd: null,
      searchState: "all",
      searchLoading: true,
      sortBy: "created_at",
      sortDirection: "desc",
      suggestionSelected: false,
    };
  },

  computed: {
    stateOptions() {
      return [
        {
          text: this.$t("models.decision.states.all"),
          value: "all",
        },
        {
          text: this.$t("models.decision.states.active"),
          value: "active",
        },
        {
          text: this.$t("models.decision.states.archived"),
          value: "inactive",
        },
      ];
    },

    decisionFields() {
      return [
        {
          key: "description",
          label: this.$i18n.t("activerecord.models.decision.one"),
          sortable: true,
        },
        {
          key: "meeting",
          label: this.$t("activerecord.models.meeting.one"),
          sortable: true,
          class: "col-shrink text-center",
        },
        {
          key: "status",
          label: this.$t("activerecord.attributes.decision.status_title"),
          class: "col-shrink",
        },
        {
          key: "status_changed_at",
          label: this.$t("models.decision.status_changed_at"),
          class: "col-shrink text-center",
          sortable: false,
        },
        {
          key: "created_at",
          label: this.$t("activerecord.attributes.decision.created_at"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "due_at",
          label: this.$t("activerecord.attributes.decision.due_at_title"),
          class: "col-shrink",
          sortable: true,
        },
        {
          key: "user_id",
          label: this.$t("activerecord.attributes.decision.assigned_to"),
          class: "col-shrink text-center",
          sortable: true,
        },
        {
          key: "options",
          label: "",
          class: "col-shrink text-center",
        },
      ];
    },

    decisionsWithRowClass() {
      return this.decisions.map((decision) => {
        return {
          ...decision,
          rowClass: `status-decisions-${decision.status}`,
        };
      });
    },

    searchDateEndAsDate() {
      return this.searchDateEnd ? new Date(this.searchDateEnd) : null;
    },

    searchDateStartAsDate() {
      return this.searchDateStart ? new Date(this.searchDateStart) : null;
    },

    maxDateStart() {
      return this.searchDateEndAsDate || new Date();
    },

    minDateEnd() {
      return this.searchDateStartAsDate;
    },

    hasSearchedForDecisions() {
      return (
        this.searchQuery ||
        this.searchDateStart ||
        this.searchDateEnd ||
        this.searchState != "all"
      );
    },
  },

  watch: {
    searchQuery: debounce(function () {
      this.searchDecisions(true);
    }, 500),

    searchDateStart() {
      if (this.dateStartSuggestion && this.dateStartSuggestion.dateString) {
        const date = new Date(this.searchDateStart);
        const suggestion = new Date(this.dateStartSuggestion.dateString);

        this.suggestionSelected =
          date.toDateString() === suggestion.toDateString();
      }

      this.searchDecisions(true);
    },

    searchDateEnd() {
      this.searchDecisions(true);
    },

    searchState() {
      this.searchDecisions(true);
    },

    suggestionSelected() {
      if (this.dateStartSuggestion && this.dateStartSuggestion.dateString) {
        if (this.suggestionSelected) {
          this.searchDateStart = this.dateStartSuggestion.dateString;
        } else {
          const date = new Date(this.searchDateStart);
          const suggestion = new Date(this.dateStartSuggestion.dateString);
          if (date.toDateString() === suggestion.toDateString()) {
            this.searchDateStart = null;
          }
        }
      }
    },
  },

  mounted() {
    this.searchDecisions();
  },

  methods: {
    referenceFor(decision_id) {
      return this.references.find((reference) => {
        return (
          reference.reference_type == "Decision" &&
          reference.reference_id == decision_id
        );
      });
    },

    async searchDecisions(resetPage) {
      const page = resetPage ? 1 : this.pagination.currentPage;
      try {
        const { data, headers } = await axios.post(
          this.url("/decisions/search"),
          {
            page: page,
            exclude_meeting_id: this.excludeMeetingId,

            search: {
              query: this.searchQuery,
              date_start: this.searchDateStart,
              date_end: this.searchDateEnd,
              state: this.searchState,
              sort_by: this.sortBy,
              sort_direction: this.sortDirection,
            },
          }
        );

        this.pagination = parsePagination(headers);
        this.decisions = data;
      } catch (error) {
        this.handleError(error);
      } finally {
        this.searchLoading = false;
      }
    },

    paginationChanged(page) {
      this.pagination.currentPage = page;
      this.searchDecisions();
    },

    sortingChanged({ sortBy, sortDirection }) {
      this.sortBy = sortBy;
      this.sortDirection = sortDirection;
      this.searchDecisions();
    },

    latestStatusChangeDate(decision) {
      const change = this.latestStatusChange(decision);
      if (change) {
        const date = new Date(change.created_at);
        const today = new Date();
        if (date.toDateString() == today.toDateString()) {
          return this.$t("application.today");
        } else {
          return this.$d(date, "date");
        }
      }
    },

    latestStatusChange(decision) {
      return decision.status_changes[decision.status_changes.length - 1];
    },

    meetingText(decision) {
      const parts = [];
      const date = new Date(decision.meeting_start_at);
      if (date) {
        parts.push(date.getFullYear());
      }
      parts.push(`#${decision.meeting_number}`);

      return parts.join(" ");
    },
  },
};
</script>
